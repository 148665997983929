import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Autoplay, Grid, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { TENANT_BUCKET } from '../../common/constants';
import { useMiddletier } from "../../common/middletier";
// import { useStoreState } from '../../common/storeContext';
import "./template_01.css";

const Template_01 = (props) => {
  // const appState = useStoreState()
  const { queries } = useMiddletier();
  const { desktopToggle, mobileToggle, titleToggle, titleIconToggle, noPadding, titlePosition, contentPosition, rowDesktop, rowMobile, numberOfRow, navigation, speed, pagination, autoplay,bgImgToggle } = props;

  const [feedback, setFeedback] = useState([])
  const speeds = speed ? speed * 1000 : 3000;
  const { t } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const paginations = {
    clickable: true,
  };

  const autoplays = {
    delay: speeds,
    disableOnInteraction: false,
  }


  // GET DATA
  useEffect(() => {
    queries([{
      index: 'getFeedback',
      method: 'getFeedback',
      params: [],
      attributes: []
    }])
      .then(({ data }) => {

        setFeedback(data['getFeedback'])
        console.log('getFeedback', data['getFeedback'])


      }).catch((error) => {
        console.log('error: ', error)
      })

  }, [queries])


  if ((desktopToggle || mobileToggle) === true) {
    return (
      <>
        <section className={`section ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"}`} id={`feedback_01`}>
          <div id={`feedback_container`}>
            <div className={`feedback_body  ${noPadding && 'pb-0'} ${isDesktopOrLaptop ? '' : 'feedback_body_m'}`}>
              <Container>
                {titleToggle ?
                  <div className={`section_header titlePosition_${titlePosition}`}>
                    <div class="font_h2 section_title">
                      {titleIconToggle && (<img src={`${TENANT_BUCKET}/icon/feedback_title_icon.png`} alt="title icon" />)}
                      {t('feedback_title')}</div>
                  </div>
                  : <></>}

                <div className="templateSliderContainer">
                  <Swiper
                    slidesPerView={isDesktopOrLaptop ? rowDesktop : rowMobile}
                    spaceBetween={0}
                    grid={{
                      rows: !numberOfRow ? 1 : (numberOfRow !== '-1' ? numberOfRow : 1),
                      fill: "row",

                    }}
                    slidesPerGroup={isDesktopOrLaptop ? rowDesktop : rowMobile}
                    autoplay={autoplay ? autoplays : false}

                    pagination={pagination ? paginations : false}
                    navigation={navigation ?? false}
                    breakpoints={{

                      320: {
                        // width: 320,
                        slidesPerView: rowMobile,
                        spaceBetween: 10,
                      },
                      // when window width is >= 640px
                      // 640: {
                      //   width: 640,
                      //   slidesPerView: 2,
                      // },
                      // when window width is >= 768px
                      768: {
                        // width: 768,
                        slidesPerView: rowMobile,
                      },
                      1000: {
                        // width: 1000,
                        slidesPerView: rowDesktop,
                      },

                    }}
                    modules={[Autoplay, Pagination, Navigation, Grid]}
                    className="w-100"
                  >

                    <div className="row">
                      {Object.values(feedback ?? "").map((item, index) => {
                        return (
                          <SwiperSlide key={`${item.id}_${index}`}>
                            <div className={`card_container`}>
                            {bgImgToggle&&(<img src={`${TENANT_BUCKET}/icon/background_feedback.png`} alt="feedback_card" />)}  
                              <div className={`card_feedback ${bgImgToggle?'bgImg_feedback_card':''} contentPosition_${contentPosition}`}>
                                <div className="feedback_info_wrap">
                                  <div className="feedback_user_wrap">
                                    <img src={item.avatar_url ?? `${TENANT_BUCKET}/icon/feedback_user.png`} alt="feedback_avatar" />
                                    <div className="feedback_userInfo">
                                      <div className="feedback_username">{item.username}</div>
                                      <div className="feedback_rating">
                                        {Array.from({ length: 5 }).map((_, rantingindex) => {
                                          const src = rantingindex < item.rating ? 'feedback_star_active' : 'feedback_star';
                                          return <img key={rantingindex} src={`${TENANT_BUCKET}/icon/${src}.png`} alt="star" />;
                                        })}</div>
                                    </div>
                                  </div>

                                  <div className="feedback_content_wrap">
                                    <div className="feedback_subject">{item.subject}</div>
                                    <div className="feedback_desc" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}

                    </div>

                  </Swiper>
                </div>
              </Container>
            </div>
          </div>
        </section>

      </>
    );
  };
}


export default Template_01;
