


import SECTION_SPORTGAMEBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_SPORTGAMECONTAINERSECTION from
'../dynamic_section/dynamic_1/template_01';





import SECTION_FOOTER_BANNER from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";


const SportPageDesktop = ()=>{
  
return (
  <div id="sportPageDesktop" class="page ">
  
  

  
  < SECTION_SPORTGAMEBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `sport_game_banner` } id={ `sport_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  
  
  

  
  < SECTION_SPORTGAMECONTAINERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `Section_sport_game` } id={ `sportGameContainer` }
    imageSize={`1x2`} contentPosition={`none`}
    actionPosition={``} rowDesktop={ 4 }
    rowMobile={ 3 } numberOfRow={ -1 }
    dataType={`game provider`} noPadding={ false } filter={`SB`}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={`center`} pagination={ true }
    autoplay={ true } navigation={ true }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  
  

  
  < SECTION_FOOTER_BANNER desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `footer_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  
  

  
</div>
)
}

export default SportPageDesktop;