



import MainBanner from '../sections/banner/template_01';







import SpeakerAnnouncement from '../sections/speaker_announcement/template_01';







import Games from '../sections/games/template_02';


import GamesM from '../sections/games/template_01';

import { AiOutlineUp } from "react-icons/ai";


import SECTION_SECTION_DISCOVER_GAMES from
  '../dynamic_section/dynamic_1/template_02';





import SECTION_SECTION_SLOT from
  '../dynamic_section/dynamic_1/template_02';





import SECTION_SECTION_LIVE_CASINO from
  '../dynamic_section/dynamic_1/template_01';





import SECTION_1675926682244 from
  '../dynamic_section/dynamic_1/template_02';



import RankingList from '../sections/ranking/template_01';


import SECTION_AFFILIATE from '../sections/affiliate/template_01';



import SECTION_SECTION_HOT_GAMES from
  '../dynamic_section/dynamic_1/template_01';


import SECTION_SECTION_HOT_GAMES_M from
  '../dynamic_section/dynamic_1/template_01';


import SECTION_SECTION_SPORTS from
  '../dynamic_section/dynamic_1/template_01';

import SECTION_SECTION_GAMETYPE from
  '../dynamic_section/dynamic_1/template_02';



import SECTION_SECTION_MAINPAGE_PROMOTION from
  '../dynamic_section/dynamic_1/template_01';

import Feedback from '../sections/feedback/template_01';


import SECTION_BRAND from
  '../dynamic_section/dynamic_2/template_01';

import SECTION_SECTION_PARTNERS from
  '../dynamic_section/dynamic_1/template_02';

import "./style.css";

import FloatingIcon from '../components/FloatingIcon';
import { useEffect, useState } from "react";
import { useMiddletier } from "../common/middletier";
import { useTranslation } from 'react-i18next';


const Main = () => {

  const [floatingIcon, setFloatingIcon] = useState([])
  const { queries, mutation } = useMiddletier()
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    queries([
      {
        index: 'getFloatingIcons',
        method: 'getFloatingIcons',
        params: [
          { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
          { code: 'website', graphqlType: 'String', required: false, value: 'MEMBER' },
        ],
        attributes: []
      }
    ])
      .then(({ data }) => {
        setFloatingIcon(data['getFloatingIcons'])
      })
      .catch((error) => {
        console.error(error)
      })
  }, [queries, i18n.resolvedLanguage])

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div id="main" class="page bg_main">





      <MainBanner navigation={false} speed={
        2.5} pagination={true} />


      <SpeakerAnnouncement desktopToggle={true} mobileToggle={
        true} iconToggle={true} titleToggle={
          false} />


      {/* < SECTION_STEP desktopToggle={true}
        mobileToggle={false} label={`Section_STEP`} id={`Section_STEP`} supportLanguage={
          true} titleToggle={false} titleIconToggle={
            false} linkTo={``} addContainer={false} numberOfImage={1} titlePosition={'center'} /> */}
            
     

      <GamesM desktopToggle={false} mobileToggle={
        true} imageToggle={true} titleToggle={
          false} gameProviderNameToggle={true} rowMobile={
            2} rowDesktop={2} position={``} imageSize={
              `1x1`} folderImage={``} menuPosition={`vertical`} />



      {/* < SECTION_SECTION_GAMETYPE desktopToggle={ true }
    mobileToggle={ false } label={ `Section_GAME_TYPE` } id={ `Section_gameType` }
    imageSize={`1x2`} contentPosition={`none`}
    actionPosition={``} rowDesktop={ 3 }
    rowMobile={ 2 } numberOfRow={ 1 }
    dataType={`gameTypes`} noPadding={ false } filter={``}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={``} pagination={ false }
    autoplay={ true } navigation={ false }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
   */}


      {/* < SECTION_SECTION_HOT_GAMES desktopToggle={true}
        mobileToggle={false} label={`Section_HOT_GAMES`} id={`Section_HOT_GAMES`}
        imageSize={`1x1`} contentPosition={`bottom`}
        actionPosition={`hover`} rowDesktop={5}
        rowMobile={2} numberOfRow={1}
        dataType={`game`} noPadding={true} filter={`SL`}
        titleToggle={true} titleIconToggle={true}
        cardPosition={``} pagination={false}
        autoplay={true} navigation={false}
        dynamicQuery={``} filterTabToggle={false}
        folderImage={``} noLink={false} /> */}

 {/* < SECTION_SECTION_HOT_GAMES_M desktopToggle={false}
        mobileToggle={true} label={`Section_HOT_GAMES`} id={`Section_HOT_GAMES`}
        imageSize={`1x1`} contentPosition={`bottom`}
        actionPosition={`hover`} rowDesktop={5}
        rowMobile={3} numberOfRow={1}
        dataType={`game`} noPadding={true} filter={`SL`}
        titleToggle={true} titleIconToggle={true}
        cardPosition={``} pagination={false}
        autoplay={true} navigation={false}
        dynamicQuery={``} filterTabToggle={false}
        folderImage={``} noLink={false} /> */}



      < SECTION_SECTION_SLOT desktopToggle={true}
        mobileToggle={false} label={`Section_SLOT`} id={`Section_SLOT`}
        imageSize={`1x2`} contentPosition={`none`}
        actionPosition={`none`} rowDesktop={3}
        rowMobile={3} numberOfRow={1}
        dataType={`game provider`} noPadding={true} filter={`SL`}
        titleToggle={true} titleIconToggle={true}
        cardPosition={``} pagination={false}
        autoplay={true} navigation={false}
        dynamicQuery={``} filterTabToggle={false}
        folderImage={``} noLink={false} />

< SECTION_SECTION_MAINPAGE_PROMOTION desktopToggle={true}
        mobileToggle={true} label={`Section_mainPage_promotion`} id={`Section_mainPage_promotion`}
        imageSize={`1x3`} contentPosition={`none`}
        actionPosition={`none`} rowDesktop={1}
        rowMobile={1} numberOfRow={1}
        dataType={`promotion`} noPadding={false} filter={``}
        titleToggle={true} titleIconToggle={true}
        cardPosition={``} pagination={true}
        autoplay={true} navigation={true}
        dynamicQuery={``} filterTabToggle={false}
        folderImage={``} noLink={false} />



    

  <RankingList desktopToggle={ true } mobileToggle={
    true } desktopBannerToggle={ false } mobileBannerToggle={
    false } badgeToggle={ true } userAvatarToggle={
    true } row={ 6 } titleIconToggle={ true } uniqueAvatarsToggle={true}/>


< SECTION_SECTION_PARTNERS desktopToggle={true}
        mobileToggle={true} label={`Section_partners`} id={`Section_partners`}
        imageSize={`1x2`} contentPosition={`none`}
        actionPosition={`none`} rowDesktop={8}
        rowMobile={3} numberOfRow={1}
        dataType={`game provider`} noPadding={true} filter={``}
        titleToggle={true} titleIconToggle={true}
        cardPosition={``} pagination={false}
        autoplay={true} navigation={false}
        dynamicQuery={``} filterTabToggle={false}
        folderImage={`logo`} noLink={true} />

< Feedback desktopToggle={ true }
    mobileToggle={ true } contentPosition={`center`} titlePosition={`start`}rowDesktop={
    4 } rowMobile={ 2 } numberOfRow={
    1 } noPadding={ false }
    titleToggle={ true } titleIconToggle={
    true } pagination={
    false } autoplay={ true } navigation={
    false }  bgImgToggle={true }/>

      {floatingIcon.length > 0 && (floatingIcon?.map(function (item, index) {
        return (
          <FloatingIcon position={item?._floating_icon?.position} enableClose={item?._floating_icon?.enable_close} enableMove={item?._floating_icon?.enable_move} desktopImageUrl={item?.desktop_image_url} mobileImageUrl={item?.mobile_image_url} url={item?._floating_icon?.url} />)
      }))}

      <button onClick={scrollToTop} className="scrollToTopButton" >
        <AiOutlineUp />
      </button>

    </div>
  )
}

export default Main;